<template>

  <div class="container" v-if="!condensed">
    <div class="title" v-if="!justify">{{ title }}</div>
    <div class="title-justified" v-if="justify">{{ title }}</div>
    <div class="content">
      {{ content ? content : '&nbsp;' }}
    </div>
  </div>

</template>

<script>
export default {
  name: "LabelLight",
  props: {
    title: String,
    content: String,
    labelClass: String,
    condensed: Boolean,
    justify: Boolean,
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template: repeat(1, 1fr) / repeat(2, 1fr);
  grid-auto-flow: row dense;
}

.content {
  font-weight: normal;
  text-align: left;
}


</style>