import BaseService from "./BaseService";

class ReportService extends BaseService {

  async getReport(req) {
    const response = await this.axios.post('/report/sale', req);
    return response.data;
  }

  async getCombinedReport(req) {
    const response = await this.axios.post('/report/combined', req);
    return response.data;
  }

  async getConalotReport(req) {
    const response = await this.axios.post('/report/conalot', req);
    return response.data;
  }

}

export default ReportService;
